import { createGlobalStyle } from 'styled-components'
import { useTheme } from '../hooks'
import colors from './colors'

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: 'Montserrat', Helvetica, sans-serif;
    }

    body {
        font-family: 'Montserrat', Helvetica, sans-serif;
        background-color: ${(props) =>
          props.isDarkMode ? colors.dark : colors.light};
        margin: 0;
        font-size: 1rem;
    }

    h1 {
      font-size: 1.6rem;
    }
    
    h2 {
      font-size: 1.4rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    h4 {
      font-size: 1rem;
    }

    h5, h6, p, li, th, td {
      font-size: 0.8rem;
    }

    ol li {
      font-size: 1.2rem;
    }

    .my__form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }

    .my__card {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }

    .my__card:hover {
      transform: scale(1.002);
    }

    .preview-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 10px;
    }
    
    .preview-item {
        position: relative;
        width: 100px;
        height: 100px;
        border: 1px solid #f4f4f4;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .preview-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        background-size: cover;
    }  

    .embla {
      overflow: hidden;
      width: 100%;
    }
    
    .embla__container {
      display: flex;
    }
    
    .embla__slide {
      position: relative;
      flex: 0 0 80%; /* Contrôle de la taille du slide */
      margin-right: 0px; /* Espace entre les slides */
    }
    
    .embla__prev, .embla__next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: #333;
      color: #fff;
      border: none;
      padding: 10px;
      cursor: pointer;
      z-index: 10;
    }
    
    .embla__prev {
      left: 10px;
    }
    
    .embla__next {
      right: 10px;
    }    
`



function GlobalStyle() {
  const { theme } = useTheme()

  return <StyledGlobalStyle isDarkMode={theme === 'dark'} />
}

export default GlobalStyle