import { useContext } from 'react'
import { ThemeContext } from '../context'
import { useQuery } from '@tanstack/react-query';

const x_xsrf_token = localStorage.getItem("cookies")

export function useTheme() {
  const { theme, toggleTheme } = useContext(ThemeContext)
  return { theme, toggleTheme }
}

// Fonction pour récupérer les données à partir d'une URL
const fetcher = async (url, token) => {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "default",
    credentials: "include",
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json',
      'X-XSRF-TOKEN': `${x_xsrf_token}` // Assurez-vous que ce token est défini quelque part
    }
  });
  
  if (!response.ok) {
    throw new Error('Erreur lors de la récupération des données');
  }
  
  return response.json();
};

export function useFetch(urlA, token) {
  const queryA = useQuery({
    queryKey: ['dataA', urlA],
    queryFn: () => fetcher(urlA, token),
    staleTime: 60000, // 1 minute avant que les données ne soient considérées comme "stale"
    cacheTime: 300000, // 5 minutes de mise en cache
  });

  return {
    isLoading11: queryA.isLoading,
    data11: queryA.data,
    error11: queryA.error,
  };
}

export function useFetch22(urlA, urlB, token) {
  const queryA = useQuery({
    queryKey: ['dataA', urlA],
    queryFn: () => fetcher(urlA, token),
    staleTime: 60000, // 1 minute avant que les données ne soient considérées comme "stale"
    cacheTime: 300000, // 5 minutes de mise en cache
  });

  const queryB = useQuery({
    queryKey: ['dataB', urlB],
    queryFn: () => fetcher(urlB, token),
    staleTime: 60000,
    cacheTime: 300000,
  });

  return {
    isLoading22: queryA.isLoading || queryB.isLoading,
    data21: queryA.data,
    data22: queryB.data,
    error22: queryA.error || queryB.error,
  };
}


export function useFetch33(urlA, urlB, urlC, token) {
  const queryA = useQuery({
    queryKey: ['dataA', urlA],
    queryFn: () => fetcher(urlA, token),
    staleTime: 60000, // 1 minute avant que les données ne soient considérées comme "stale"
    cacheTime: 300000, // 5 minutes de mise en cache
  });

  const queryB = useQuery({
    queryKey: ['dataB', urlB],
    queryFn: () => fetcher(urlB, token),
    staleTime: 60000,
    cacheTime: 300000,
  });

  const queryC = useQuery({
    queryKey: ['dataC', urlC],
    queryFn: () => fetcher(urlC, token),
    staleTime: 60000,
    cacheTime: 300000,
  });

  return {
    isLoading33: queryA.isLoading || queryB.isLoading || queryC.isLoading,
    data31: queryA.data,
    data32: queryB.data,
    data33: queryC.data,
    error33: queryA.error || queryB.error || queryC.error,
  };
}

export function useFetch44(urlA, urlB, urlC, urlD, token) {
  const queryA = useQuery({
    queryKey: ['dataA', urlA],
    queryFn: () => fetcher(urlA, token),
    staleTime: 60000, // 1 minute avant que les données ne soient considérées comme "stale"
    cacheTime: 300000, // 5 minutes de mise en cache
  });

  const queryB = useQuery({
    queryKey: ['dataB', urlB],
    queryFn: () => fetcher(urlB, token),
    staleTime: 60000,
    cacheTime: 300000,
  });

  const queryC = useQuery({
    queryKey: ['dataC', urlC],
    queryFn: () => fetcher(urlC, token),
    staleTime: 60000,
    cacheTime: 300000,
  });

  const queryD = useQuery({
    queryKey: ['dataD', urlD],
    queryFn: () => fetcher(urlD, token),
    staleTime: 60000,
    cacheTime: 300000,
  });

  return {
    isLoading44: queryA.isLoading || queryB.isLoading || queryC.isLoading || queryD.isLoading,
    data41: queryA.data,
    data42: queryB.data,
    data43: queryC.data,
    data44: queryD.data,
    error44: queryA.error || queryB.error || queryC.error || queryD.error,
  };
}