import React from 'react';
import { useFetch22 } from "../../../hooks";
import Error from "../widgets/error";
import Loading from "../widgets/loading";

export default function Search() {
    const token = JSON.parse(localStorage.getItem("token") || "null");

    const { isLoading22, data21, data22, error22 } = useFetch22(
        'https://api.book-easily.com/api/towns',
        'https://api.book-easily.com/api/categories',
        token
    );

    const towns = data21?.data || [];
    const categories = data22?.data || [];

    if (error22) {
        console.error('Failed to fetch data:', error22);
        return <Error />;
    }

    if (isLoading22) {
        return <Loading />;
    }

    return (
        <div className="container mb-3 mb-lg-5">
            <form action="/results/q=" method="GET">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 p-2 g-2 bg-white shadow my__card rounded-4">
                    <div className="col d-flex align-items-center justify-content-center p-2 border-end">
                        <div className="col-12">
                            <select className="form-select rounded-5" id="town" name="town" aria-label="Select town">
                                <option value="">Ville...</option>
                                {towns.map(town => (
                                    <option key={`${town.town}-${town.id}`} value={town.id}>{town.town}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center p-2 border-end">
                        <div className="col-12">
                            <select className="form-select rounded-5" id="category" name="category" aria-label="Select category">
                                <option value="">Categorie...</option>
                                {categories.map(category => (
                                    <option key={`${category.name}-${category.id}`} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center p-2 border-end">
                        <div className="col-12">
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control rounded-5"
                                    id="number_persons"
                                    name="number_persons"
                                    placeholder="Nombre de personnes..."
                                    aria-label="Number of persons"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center p-2">
                        <div className="col-12">
                            <button
                                type="submit"
                                className="btn w-100 btn-outline-warning me-2 fw-bold rounded-5"
                                aria-label="Find accommodation"
                            >
                                Chercher <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
