import { Link } from "react-router-dom";
import { useTheme } from "../../../hooks";
import { ImgCard, SpanCartNew, SpanCartPrices, SpanCartStanding, StyledLink, UserProfil } from '../../../styles/atoms';

function CardAds({ id, name, user_photo_url, user_first_name, category, designation, type, town, street, cover, price, view, free_booking, is_new, reff, standing }) {
  const { theme } = useTheme();

  // Préparation des données en dehors du JSX pour éviter les calculs dans le render
  const formattedDesignation = designation
    ? designation.split(' ').join('_').split('&').join('_').split('/').join('_')
    : '';
  const propertyLink = `/${category}/property/${formattedDesignation}/${id}`;
  const facebookShareUrl = encodeURIComponent(`https://www.book-easily.com${propertyLink}`);
  const whatsappMessage = encodeURIComponent(`${type} - ${category} de ${formattedDesignation} à ${town} - ${street} disponible sur BookEasily. Consultez ici: https://www.book-easily.com${propertyLink}`);

  // Fallback image
  const handleImageError = (e) => {
    e.target.src = "https://api.book-easily.com/storage/assets/NoImage.jpg"; // Mettre une image par défaut
  };

  return (
    <div className="col p-1" key={reff}>
      <div className="card my__card">
        <div>
          <StyledLink theme={theme} to={propertyLink}>
            <ImgCard
              srcSet={`${cover[0]}`}
              src={cover[0]}
              loading="lazy"
              className="card-img img-fluid"
              alt={`${name}-${street}`}
              onError={handleImageError}
            />
          </StyledLink>

          <SpanCartPrices>
            {category === 'hôtels' && `${price.days} XAF`}
            {category === 'meublés' && `${price.days} XAF`}
            {category === 'non meublés' && `${price.months} XAF`}
          </SpanCartPrices>

          {is_new === 1 && <SpanCartNew>Nouveau 🎊</SpanCartNew>}
          
          {standing && (
            <SpanCartStanding>
              {'⭐'.repeat(standing)}
            </SpanCartStanding>
          )}
        </div>

        <div className="card-body">
          <h4 className="text-capitalize" style={{ maxWidth: 320, fontWeight: 600 }}>
            {designation}
          </h4>
          <p className="card-text text-truncate" style={{ maxWidth: 320 }}>
            {name}
          </p>
        </div>

        
        <ul className="list-group list-group-flush">
          <li className="list-group-item text-capitalize">
              <UserProfil src={ `https://api.book-easily.com/${user_photo_url}` } alt={user_first_name} /> {user_first_name}
          </li>
          <li className="list-group-item text-capitalize">
            {category === 'non meublés' ? (
              <i className="bi bi-house-door-fill text-warning"></i>
            ) : (
              <i className="bi bi-building-fill text-warning"></i>
            )} {type} - {category}
          </li>
          <li className="list-group-item text-capitalize">
            <i className="bi bi-geo-alt-fill text-danger"></i> {town} - {street}
          </li>
          <li className="list-group-item">
            {free_booking === 1 ? (
              <i className="bi bi-patch-check-fill text-success"></i>
            ) : (
              <i className="bi bi-patch-check-fill text-danger"></i>
            )}
            {free_booking === 1 ? ' Payez sur place' : ' Réservation payante'}
          </li>
        </ul>

        <div className="card-footer gx-1 row align-items-center">
          <div className="col-3">
            <div className="row g-1">
              <div className="col fb-share-button" data-href={`https://www.book-easily.com${propertyLink}`} data-layout="button" data-size="small">
                <Link target="_blank" to={`https://www.facebook.com/sharer/sharer.php?u=${facebookShareUrl}`} className="fb-xfbml-parse-ignore">
                  <i className="bi bi-facebook"></i>
                </Link>
              </div>
              <Link className="col" to={`https://api.whatsapp.com/send?text=${whatsappMessage}`} target="_blank">
                <i className="bi bi-whatsapp text-success"></i>
              </Link>
            </div>
          </div>

          <div className="col-6">
            <Link to={propertyLink} className="btn btn-outline-warning w-100 rounded-5 fw-bold">
              Consultez
            </Link>
          </div>

          <div className="col-3 text-end">
            <i className="bi bi-eye"> {view}</i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAds;
