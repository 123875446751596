import React, { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTheme } from "../../../hooks";
import { StyledTitle, TownSection } from "../../../styles/atoms";
import CardTowns from '../cards/cardTowns';

function Towns({ villes }) {
    const { theme } = useTheme();

    // Définir le nombre de slides à afficher en fonction de la taille de l'écran
    const [slidesToShow, setSlidesToShow] = useState(1);
    const [isPlaying, setIsPlaying] = useState(true); // Indicateur pour l'autoplay

    // Utiliser useEffect pour ajuster slidesToShow en fonction de la taille de l'écran
    useEffect(() => {
        const updateSlidesToShow = () => {
            const width = window.innerWidth;
            if (width > 1440) {
                setSlidesToShow(4);  // Très grands écrans
            } else if (width > 1080) {
                setSlidesToShow(3);  // Grands écrans
            } else if (width > 768) {
                setSlidesToShow(2);  // Tablettes
            } else {
                setSlidesToShow(1);  // Mobiles
            }
        };

        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);

        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    // Configuration du carrousel Embla
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,       // Boucle infinie
        align: 'start',   // Aligner à gauche
        skipSnaps: false, // Ne pas ignorer les snaps
        slidesToScroll: slidesToShow,  // Définit combien de slides défilent
    });

    // // Fonction pour passer au slide suivant
    // const scrollNext = useCallback(() => {
    //     if (emblaApi) emblaApi.scrollNext();
    // }, [emblaApi]);

    // // Fonction pour revenir au slide précédent
    // const scrollPrev = useCallback(() => {
    //     if (emblaApi) emblaApi.scrollPrev();
    // }, [emblaApi]);

    // Autoplay - Utilise un intervalle pour faire défiler automatiquement les slides
    useEffect(() => {
        if (!emblaApi || !isPlaying) return;

        const autoplay = setInterval(() => {
            emblaApi.scrollNext();
        }, 3000); // 3000ms = 3 secondes entre chaque slide

        // Nettoyer l'intervalle quand le carrousel change ou l'utilisateur interagit
        return () => clearInterval(autoplay);
    }, [emblaApi, isPlaying]);

    // Stop l'autoplay quand l'utilisateur interagit
    const handleUserInteraction = () => {
        setIsPlaying(false); // Stop autoplay lors de l'interaction
    };

    return (
        <TownSection>
            <div className="col-12">
                <StyledTitle theme={theme}>Destinations</StyledTitle>
                <p className="visually-hidden">
                    Allez à la découverte des bons coins dans chaque ville du Cameroun. Vous pourriez choisir la ville qui vous intéresse et BookEasily vous proposera plusieurs logements disponibles dans celle-ci !
                </p>
                <div className="col-12 embla" ref={emblaRef} onMouseEnter={handleUserInteraction}>
                    <div className="embla__container">
                        {villes.map(({ id, town, cover, n_ads, description }) => (
                            <div className="embla__slide" key={id} style={{ flex: `0 0 ${100 / slidesToShow}%` }}>
                                <CardTowns
                                    id={id}
                                    town={town}
                                    cover={cover}
                                    count={n_ads}
                                    description={description}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </TownSection>
    );
}

export default Towns;
